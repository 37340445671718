import { minimatch } from "minimatch"

export function addTrailingSlash(str: string) {
  if (str.endsWith("/")) {
    return str
  }

  return `${str}/`
}

type ReplacerFn = ((key: string, value: unknown) => unknown) | null | undefined

function createReplacer(replacer?: ReplacerFn) {
  return (key: string, value: unknown) => {
    if (typeof value === "bigint") {
      return value.toString()
    }

    return replacer ? replacer(key, value) : value
  }
}

export function safeStringify(value: unknown, replacer?: ReplacerFn, space?: string | number) {
  return JSON.stringify(value, createReplacer(replacer), space)
}

export function safeParseJson<T = unknown>(value: unknown): T | undefined {
  if (typeof value !== "string") {
    return undefined
  }

  try {
    return JSON.parse(value)
  } catch {
    return undefined
  }
}

export function matchesGlob(url: string | undefined, globs: string[]) {
  if (!url) {
    return false
  }

  for (const glob of globs) {
    const matches = minimatch(url, glob)
    if (matches) {
      return true
    }
  }

  return false
}

export function bufferToJson(buf: Buffer): unknown {
  try {
    return JSON.parse(buf.toString())
  } catch {}
}

export function generateEan() {
  const EAN_LENGTH = 18
  const EAN_PREFIX = "8591824"
  const ADDITIONAL_PREFIX = "111111"

  let barcodeBody = EAN_PREFIX + ADDITIONAL_PREFIX
  // Generujeme zbývající část kódu kromě kontrolní číslice, náhodně
  while (barcodeBody.length < EAN_LENGTH - 1) {
    const randomDigit = Math.floor(Math.random() * 10).toString() // Generuje náhodnou číslici od 0 do 9
    barcodeBody += randomDigit
  }

  let sum = 0
  for (let i = 0; i < barcodeBody.length; ++i) {
    const digit = parseInt(barcodeBody[i] ?? "", 10)
    // Pozice pro výpočet sumy se liší, protože generujeme kód od začátku, nikoli od konce
    sum += i % 2 === 0 ? digit * 3 : digit
  }

  const mod = sum % 10
  const calculatedChecksum = mod === 0 ? 0 : 10 - mod
  const completeEan = barcodeBody + calculatedChecksum.toString()
  return completeEan
}

// used solution from https://stackoverflow.com/a/55292366
export function trimChar(str: string, ch: string) {
  let start = 0
  let end = str.length

  while (start < end && str[start] === ch) {
    ++start
  }

  while (end > start && str[end - 1] === ch) {
    --end
  }

  return start > 0 || end < str.length ? str.substring(start, end) : str
}
