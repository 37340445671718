export function isNumberBetween(value: number, bottom: number, top: number) {
  return value >= bottom && top >= value
}

/**
 * Returns a number whose value is limited to the given range.
 *
 * Example: limit the output of this computation to between 0 and 255
 * clamp(10000, 0, 255)
 *
 * @param {Number} num Input number
 * @param {Number} min The lower boundary of the output range
 * @param {Number} max The upper boundary of the output range
 * @returns A number in the range [min, max]
 * @type Number
 */
export function clamp(num: number, min: number, max: number) {
  return Math.min(Math.max(num, min), max)
}

export function round(num: number, decimalSpaces = 2) {
  const multiplier = Math.pow(10, decimalSpaces)
  return Math.round((num + Number.EPSILON) * multiplier) / multiplier
}

export function randomUInt16() {
  return Math.floor(Math.random() * 65536)
}

export function randomUint8() {
  return Math.floor(Math.random() * 256)
}

// Generates a random 16-bit number in the format: XXXXXXXX00000000 (e.g., 1001000100000000).
// This format is used to maintain backward compatibility with the previous implementation
// and to reserve space for special flags on the DL when creating tokens.
export function randomUInt16WithTrailingZerosInSecondByte() {
  return randomUint8() << 8
}
