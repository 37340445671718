import dayjs from "dayjs"

import { clamp } from "./math"

export function formatMinutesAsTimeString(minutes: number): string {
  const input = clamp(minutes, 0, 1440 - 1)
  const hours = Math.floor(input / 60)
  const minutesLeft = input % 60
  return `${hours.toString().padStart(2, "0")}:${minutesLeft.toString().padStart(2, "0")}`
}

export function parseTimeStringAsMinutes(timeString: string): number | undefined {
  const [hours = 0, minutes = 0] = timeString.split(":").map((val) => parseInt(val, 10))
  const result = hours * 60 + minutes
  return Number.isNaN(result) ? undefined : result
}

export function generateDateArray(
  start: Date,
  end: Date,
  options?: { unit?: dayjs.ManipulateType; interval?: number; includeEnd?: boolean },
): Date[] {
  const { unit = "day", interval = 1, includeEnd = true } = options || {}

  const isStartAfterEnd = dayjs(start).isAfter(end)
  const from = isStartAfterEnd ? end : start
  const to = isStartAfterEnd ? start : end

  const dateArray = []

  let current = from
  while (current <= to) {
    if (!includeEnd && current >= to) {
      break
    }

    dateArray.push(new Date(current))
    current = dayjs(current).add(interval, unit).toDate()
  }

  return isStartAfterEnd ? dateArray.reverse() : dateArray
}

export function daysToSeconds(days: number) {
  return days * 24 * 60 * 60
}

export function hoursToSeconds(hours: number) {
  return hours * 60 * 60
}

// TODO test and rename
export function stripDate(date: Date) {
  return dayjs(date).set("year", 0).set("month", 0).set("date", 0).set("seconds", 0).set("milliseconds", 0).toDate()
}

export function setFullDate(date: Date, newDate: Date) {
  return dayjs(date)
    .set("date", newDate.getDate())
    .set("month", newDate.getMonth())
    .set("year", newDate.getFullYear())
    .set("seconds", 0)
    .set("milliseconds", 0)
    .toDate()
}

export function getStartOfSlot(date: Date) {
  return dayjs(date)
    .minute(Math.floor(date.getMinutes() / 15) * 15)
    .second(0)
    .millisecond(0)
}

export function getEndOfSlot(date: Date) {
  return getStartOfSlot(date).add(15, "minutes")
}
